.terminal {
    background-color: #202020;
    color: #FFFFFF;
    padding: 5px;
    border-radius: 0px;
    font-family: 'Courier New', Courier, monospace;
    white-space: pre;
    font-size: 16px;
    line-height: 1.5;
    max-width: 600px;
    margin: 50px auto;
    position: relative;
  }
  
  .blink-cursor {
    animation: blink 1s infinite;
  }

  .empty {
    height: 3rem; 
}
  
  @keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  .command {
    color: #00FF00; /* Green color for commands */
  }
  
  .output {
    color: #FFFFFF; /* White color for output */
  }
  
  .terminal-header {
    display: flex;
    align-items: center;
    padding: 4px 16px;   /* Increased horizontal padding for better spacing */
    background: #333;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin-bottom: 10px;  /* Added a small margin to separate header from content */
  }
  
  .terminal-button {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;   /* Increased margin for better spacing */
  }
  
  .terminal-button.red {
    background: #E74C3C;  /* Adjusted colors to match the visual */
  }
  
  .terminal-button.yellow {
    background: #F1C40F;
  }
  
  .terminal-button.green {
    background: #2ECC71;
  }
  
  .info {
    color: #FFFF00;  /* Yellow color for info lines like portfolio@0.1.0 */
  }
